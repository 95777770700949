import React from "react";
import { EagleHouse, LionHouse, SeahorseHouse } from "../images";
import {
  FaDislike,
  FaEagle,
  FaLike,
  FaLion,
  FaSeaHorse,
  FaStronglyDislike,
  FaStronglyLike,
  FaUnsure,
} from "../images/svg";

export const CareerGroupsTabs = {
  Careerbag: "Careerbag",
  BrightOutlook: "BrightOutlook",
  Careercluster: "Careercluster",
  CareerSorted: "CareerSorted",
  JobPrepration: "JobPrepration",
  SearchNow: "SearchNow",
};

export const RateType = {
  Hourly: "Hourly",
  Annual: "Annual",
};

export const SortDirections = {
  Descending: "Descending",
  Ascending: "Ascending",
};

export const TrainingFilter = {
  ShowAll: "ShowAll",
  ShowRecentGraduates: "ShowRecentGraduates",
};

export const ClustersData = {
  "1.0000": {
    url: "agriculture-food-and-natural-resources-overview",
    description:
      "The production, processing, marketing, distribution, financing, and development of agricultural commodities and resources including food, fiber, wood products, natural resources, horticulture, and other plant and animal products or resources.",
  },
  "2.0000": {
    url: "construction-overview",
    description:
      "Designing, planning, managing, building and maintaining the built environment.",
  },
  "3.0000": {
    url: "arts-av-technology-and-communications-overview",
    description:
      "Designing, producing, exhibiting, performing, writing, and publishing multimedia content including visual and performing arts and design, journalism and entertainment services.",
  },
  "4.0000": {
    url: "business-management-and-administration-overview",
    description:
      "Planning, organizing, directing and evaluating business functions essential to efficient and productive business operations.",
  },
  "5.0000": {
    url: "education-and-training-overview",
    description:
      "Planning, managing and providing education and training services, and related learning support services.",
  },
  "6.0000": {
    url: "finance-and-insurance-overview",
    description:
      "Planning, services for financial and investment planning, banking, insurance, and business financial management.",
  },
  "7.0000": {
    url: "government-and-public-administration-overview",
    description:
      "Planning and performing government functions at the local, state and federal levels, including governance, national security, foreign service, planning, revenue and taxation, and regulations.",
  },
  "8.0000": {
    url: "healthcare-overview",
    description:
      "Planning, managing, and providing therapeutic services, diagnostic services, health informatics, support services, and biotechnology research and development.",
  },
  "9.0000": {
    url: "hospitality-and-tourism-overview",
    description:
      "Management, marketing and operations of restaurants and other food services, lodging, attractions, recreation events and travel related services.",
  },
  "10.0000": {
    url: "human-services-overview",
    description:
      "Preparing individuals for employment in careers that relate to families and human needs such as counseling and mental health services, family and community services, personal care and consumer services.",
  },
  "11.0000": {
    url: "information-technology-overview",
    description:
      "The design, development, support and management of hardware, software, multimedia and systems integration services.",
  },
  "12.0000": {
    url: "law-public-safety-and-corrections-overview",
    description:
      "Planning, managing, and providing legal, public safety and protective services and homeland security, including professional and technical support services.",
  },
  "13.0000": {
    url: "manufacturing-overview",
    description:
      "Planning, managing and performing the processing of materials into intermediate or final products and related professional and technical support activities such as production planning and control, maintenance and manufacturing, and process engineering.",
  },
  "14.0000": {
    url: "marketing-overview",
    description:
      "This cluster covers careers in planning, managing, and performing marketing activities to reach organizational objectives.",
  },
  "15.0000": {
    url: "stem-overview",
    description:
      "Planning, managing and providing scientific research and professional and technical services (e.g., physical science, social science, engineering) including laboratory and testing services, and research and development services.",
  },
  "16.0000": {
    url: "transportation-distribution-and-logistics-overview",
    description:
      "Planning, management, and movement of people, materials, and goods by road, pipeline, air, rail and water and related professional support services such as transportation infrastructure planning and management, logistics services, mobile equipment and facility maintenance.",
  },
};

export const CareerOneStopApiKey = process.env.REACT_APP_ONET_TOKEN_KEY; // careerOneStopApi key
export const CareerOneStopApiUrl = process.env.REACT_APP_CAREER_ONE_STOP_API; // careerOneStop api url
export const CareerOneStopUserId = process.env.REACT_APP_USER_ID;

export const logoColors = {
  red: "#800020",
  yellow: "#D4AF37",
  green: "#047B69",
  gray: "#F5F5DC",
  all: "all",
};

export const HowWeSolveProblemsSteps = {
  interviews: {
    id: 1,
    title: "Interviews",
    heading: "Career Insights",
    text: "Dive into the world of various professions with SOS's unique interview series. We bring you engaging, coffee chat-style discussions with distinguished professionals from diverse fields. These interviews offer students a rare glimpse into the realities of different careers, providing personal insights, experiences, and advice. Our aim is to help students understand the nuances of various professions, guiding them to make informed decisions about their future career paths. Join us to gain an insider's perspective and expand your career horizons.",
  },
  articles: {
    id: 2,
    title: "Articles",
    heading: "Get Updated",
    text: "At SOS, we provide high-quality, insightful articles that dive deep into a wide array of professions. Our articles offer a comprehensive look at various careers, covering everything from job responsibilities and industry trends to the skills required for success. Whether you're exploring career options or looking to advance in your chosen field, these well-researched and frequently updated articles will equip you with the knowledge needed to make informed decisions. Discover valuable perspectives, practical advice, and the latest developments in your field of interest—all designed to help you shape a fulfilling career path.",
  },
  communityPlusMentors: {
    id: 3,
    title: "Community + Mentors",
    heading: "Engage & elevate",
    text: "Our vibrant community space at SOS offers a unique blend of professional and casual interactions. Whether you're seeking career advice, networking opportunities, or just a place to chat, our forums and help sections provide a welcoming environment for all. Driven by our innovative housing system, members can engage in specialized discussions, share insights, and collaborate across fields. From career-focused networking to casual conversations, our community fosters meaningful connections, helping you grow both personally and professionally.",
  },
  certificatesAcheivementAwards: {
    id: 4,
    title: "Certificates and achievement awards",
    heading: "Celebrate your Success",
    text: "At SOS, we celebrate the hard work and dedication of our members. Our Achievement Awards are given to active participants who meet rigorous standards across all aspects of the organization, recognizing their exceptional contributions. Additionally, Certificates of Recognition will be awarded to our internal team members and volunteers who help SOS grow and thrive. These certificates and awards not only honor your achievements but also provide valuable credentials to enhance your resume and showcase your commitment to making a difference.",
  },
  scholarships: {
    id: 5,
    title: "scholarships",
    heading: "Funding Your Dreams",
    text: "At SOS, we're committed to supporting students in achieving their academic and career goals. Our upcoming scholarship opportunities will provide financial assistance to help you pursue your dreams and unlock your full potential. Stay tuned for more details on how you can apply and take the next step toward a brighter future",
  },
  internshipVolunteer: {
    id: 7,
    title: "Internship/Volunteer",
    heading: "Join Our Mission",
    text: "Join the Society of Scholars as a volunteer and actively contribute to our mission. Whether you're interested in participating in podcasts, writing insightful articles, engaging in outreach, assisting with funding, or contributing to other exciting projects, there's a place for you on our team. Volunteering with SOS is a unique opportunity to build valuable skills, gain hands-on experience, and enhance your resume. Ready to make a difference and grow alongside us? Apply now to become a part of the SOS team and start shaping your future today!",
  },
};

export const FAQsData = [
  {
    id: 1,
    title:
      "What are the benefits of being a Team member of the SOS organization?",
    details: [
      "Networking with Industry Leaders: As a member, you'll have unique opportunities to connect with professionals and leaders across various industries. These connections can offer valuable insights into career paths, industry trends, and potential job openings.",
      "Career Insight and Guidance: Engage in events and workshops that provide a deeper understanding of different career paths. This can be especially beneficial in determining if a particular career is a good fit for your skills and interests.",
      "Internship and Scholarship Opportunities: Building personal relationships within the organization can lead to internship and scholarship opportunities. Members often gain access to exclusive information about these openings.",
      "Volunteering Certificates and Recognition: Your volunteer work with SOS is recognized through certificates. These not only showcase your commitment to community service but also add value to your resume.",
      "Skill Development: Members have the chance to develop a wide range of skills:",
      "Technical Proficiency: Gain hands-on experience with industry-relevant tools and technologies.",
      "Project Management: Learn how to plan, execute, and manage projects efficiently.",
      "Collaboration: Work in team settings, enhancing your ability to work effectively with diverse groups.",
      "Problem-Solving: Tackle real-world problems, developing critical thinking and innovative solutions.",
      "Empathy and Communication: Enhance your interpersonal skills, crucial for leadership roles and professional growth.",
      "Diverse Departmental Experience: Explore different roles in departments like research, education, communication, and administration, which can broaden your understanding of organizational operations.",
    ],
  },
  {
    id: 2,
    title: "How can I join SOS?",
    details: [
      "Educational Outreach: Programs designed to promote education in underprivileged communities.",
      "Environmental Initiatives: Projects aimed at protecting and preserving the environment.",
      "Health and Wellness Campaigns: Organizing health camps and wellness drives for marginalized groups.",
      "Technology and Innovation: Initiatives that use technology to solve social issues.",
      "Community Empowerment: Empowering local communities through training and skill development.",
    ],
  },
  {
    id: 3,
    title: "What kind of events or activities does SOS organize?",
    details: [
      "Sign up on the website: Register via the official SOS platform.",
      "Attend Orientation: Attend a mandatory orientation session for new volunteers.",
      "Select a Project: Choose from a list of ongoing projects where your skills are needed.",
      "Submit Availability: Provide your availability schedule to match with project timelines.",
      "Receive Volunteer Kit: Get materials and guidance to assist you in your volunteer role.",
    ],
  },
  {
    id: 4,
    title: "How can I stay updated about upcoming SOS events and activities?",
    details: [
      "Communication Skills: Effective communication is key in working with communities.",
      "Leadership: Ability to take initiative and lead smaller project groups.",
      "Technical Proficiency: Familiarity with relevant tools and technologies.",
      "Collaboration: Work efficiently within diverse teams.",
      "Problem-Solving: Address real-world challenges with innovative solutions.",
    ],
  },
];
export const QuestionTypes = {
  ThirtyQuestions: 30,
  SixtyQuestions: 60,
};
export const ProfilerSteps = {
  enterScores: -1,
  welcome: 0,
  howItWorks: 1,
  notATest: 2,
  questionList1: 3,
  questionList2: 4,
  questionList3: 5,
  questionList4: 6,
  questionList5: 7,
  greatJob: 8,
  profilerResults: 9,
  interestPlusJobZone: 10,
  currentOrFuture: 11,
  // exploreJobZones: 12,
  selectJobZone: 12,
  // interestPlusJobZoneResult: 14,
  careersByInterests: 13,
  resultsWithSelectedCareers: 14,
  houseAssignedConfetti: 15,
};

export const ProfilerPrintPagesParams = {
  unsure: "unsure",
  "interest-areas": "interest-areas",
  "job-zones": "job-zones",
  "interest-wise-careers": "interest-wise-careers",
};

export const LocalStorageKeyNames = {
  mcqAnswers: "mcqAnswers",
  sixtyQuestions: "sixtyQuestions",
  thirtyQuestions: "thirtyQuestions",
  selectedQuestionsType: "selectedQuestionsType",
};

export const JobZones = [
  { id: 1, label: "Little or no" },
  { id: 2, label: "Some" },
  { id: 3, label: "Medium" },
  { id: 4, label: "High" },
  { id: 5, label: "Extensive" },
];

export const NumbersSpellings = ["Zero", "One", "Two", "Three", "Four", "Five"];
export const InterestAreasData = {
  Realistic: {
    description:
      "People with Realistic interests like work that includes practical, hands-on problems and answers. Often people with Realistic interests do not like careers that involve paperwork or working closely with others.",
    list: [
      "Working with plants and animals",
      "Real-world materials like wood, tools, and machinery",
      "Outside work",
    ],
  },
  Investigative: {
    description:
      "People with Investigative interests like work that has to do with ideas and thinking rather than physical activity or leading people.",
    list: ["Searching for facts", "Figuring out problems"],
  },
  Artistic: {
    description:
      "People with Artistic interests like work that deals with the artistic side of things, such as acting, music, art, and design.",
    list: [
      "Creativity in their work",
      "Work that can be done without following a set of rules",
    ],
  },
  Social: {
    description:
      "People with Social interests like working with others to help them learn and grow. They like working with people more than working with objects, machines, or information.",
    list: [
      "Teaching",
      "Giving advice",
      "Helping and being of service to people",
    ],
  },
  Enterprising: {
    description:
      "People with Enterprising interests like work that has to do with starting up and carrying out business projects. These people like taking action rather than thinking about things.",
    list: [
      "Persuading and leading people",
      "Making decisions",
      "Taking risks for profits",
    ],
  },
  Conventional: {
    description:
      "People with Conventional interests like work that follows set procedures and routines. They prefer working with information and paying attention to details rather than working with ideas.",
    list: ["Working with clear rules", "Following a strong leader"],
  },
};
export const InterestAreas = {
  Realistic: ["bg-[#03AC3A]", "text-[#03AC3A]"],
  Investigative: ["bg-[#FF0086]", "text-[#FF0086]"],
  Artistic: ["bg-[#833636]", "text-[#833636]"],
  Social: ["bg-[#670FEA]", "text-[#670FEA]"],
  Enterprising: ["bg-[#FF9125]", "text-[#FF9125]"],
  Conventional: ["bg-[#02D3C9]", "text-[#02D3C9]"],
};

export const CarouselSettings = {
  dots: false,
  infinite: true,
  speed: 3000,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  cssEase: "linear",
  arrows: false,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        speed: 100,
        slidesToShow: 2,
        autoplay: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        speed: 100,
        slidesToShow: 2,
        autoplay: false,
      },
    },
  ],
};

export const ParallexSpeed = {
  xl: {
    Empowering: 0.2,
    Academic: 0.2,
    para: 0.15,
    para2: 0.05,
    Enhancing: 0.1,
    ProgressThrough: 0.1,
    Customized: 0.1,
    Enriching: 0.1,
    Students: 0.15,
    circle: 0.1,
  },
  lg: {
    Empowering: 0.2,
    Academic: 0.2,
    para: 0.15,
    para2: 0.05,
    Enhancing: 0.1,
    ProgressThrough: 0.1,
    Customized: 0.1,
    Enriching: 0.1,
    Students: 0.15,
    circle: 0.1,
  },
  md: {
    Empowering: 0.2,
    Academic: 0.05,
    para: 0.15,
    para2: 0.05,
    Enhancing: 0.1,
    ProgressThrough: 0.1,
    Customized: 0.1,
    Enriching: 0.1,
    Students: 0.15,
    circle: 0.1,
  },
  sm: {
    Empowering: 0.2,
    Academic: 0.05,
    para: 0.15,
    para2: 0.05,
    Enhancing: 0.1,
    ProgressThrough: 0.1,
    Customized: 0.1,
    Enriching: 0.1,
    Students: 0.05,
    circle: 0.1,
  },
  xs: {
    Empowering: 0.2,
    Academic: 0.05,
    para: 0.15,
    para2: 0.05,
    Enhancing: 0.1,
    ProgressThrough: 0.1,
    Customized: 0.1,
    Enriching: 0.1,
    Students: 0.05,
    circle: 0.1,
  },
};
export const ParallexPositions = {
  xl: {
    Empowering: -140,
    Academic: 140,
    para: 250,
    para2: 200,
    Enhancing: -140,
    ProgressThrough: -100,
    Customized: 0.1,
    Enriching: -140,
    Students: -350,
    circle: 0,
  },
  lg: {
    Empowering: -40,
    Academic: 150,
    para: 250,
    para2: 100,
    Enhancing: -40,
    ProgressThrough: 150,
    Customized: 0.1,
    Enriching: -40,
    Students: -300,
    circle: 0,
  },
  md: {
    Empowering: 0,
    Academic: 80,
    para: 250,
    para2: 100,
    Enhancing: 0,
    ProgressThrough: 100,
    Customized: 0.1,
    Enriching: 0,
    Students: -200,
    circle: 0,
  },
  sm: {
    Empowering: 40,
    Academic: 60,
    para: 250,
    para2: 40,
    Enhancing: 40,
    ProgressThrough: 100,
    Customized: 0.1,
    Enriching: 0,
    Students: 0,
    circle: 0,
  },
  xs: {
    Empowering: 0,
    Academic: 20,
    para: 100,
    para2: 40,
    Enhancing: 0,
    ProgressThrough: 100,
    Customized: 0.1,
    Enriching: -40,
    Students: -20,
    circle: 0,
  },
};

export const TypeOfAnswers = [
  { img: FaStronglyDislike, text: "Strongly Dislike" },
  { img: FaDislike, text: "Dislike" },
  { img: FaUnsure, text: "Unsure" },
  { img: FaLike, text: "Like" },
  { img: FaStronglyLike, text: "Strongly Like" },
];

export const SmoothScrollTypes = {
  blackLayout: "blackLayout",
  other: "other",
};

export const houseName = {
  LION: "LION",
  EAGLE: "EAGLE",
  SEAHORSE: "SEAHORSE",
};

export const houseScoreArea = {
  INVESTIGATIVE: "Investigative",
  SOCIAL: "Social",
  ARTISTIC: "Artistic",
  CONVENTIONAL: "Conventional",
  ENTERPRISING: "Enterprising",
  REALISTIC: "Realistic",
};

export const houseColors = {
  EAGLE: "#D4AF37",
  LION: "#800020",
  SEAHORSE: "#047B69",
  DEFAULT_COLORS: [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4CAF50",
    "#8BC34A",
    "#CDDC39",
    "#FFEB3B",
    "#FFC107",
    "#FF9800",
    "#FF5722",
  ],
};

export const HousesData = {
  LION: {
    img: FaLion,
    houseImg: LionHouse,
    title: "House of Lion",
    strengths: "Enterprising & Conventional",
    motto: "Lead with courage and inspire others to greatness.",
    para: `
The House of Lion stands for strength, integrity, and decisive leadership. Lions thrive in goal-driven environments, inspiring others with determination and purpose. They foster unity, organize effectively, and work tirelessly to achieve collective success.`,
    count: "lion_count",
    color: "custom-dark-red",
  },
  EAGLE: {
    img: FaEagle,
    houseImg: EagleHouse,
    title: "House of Eagle",
    strengths: "Investigative & Social",
    motto: "Seek wisdom and connect with purpose.",
    para: "The House of Eagle represents wisdom, curiosity, and growth. Eagles are visionaries who combine sharp intellect with strong connections, thriving on solving challenges and exploring new ideas. Through meaningful relationships and insights, you inspire progress and innovation in all you do.",
    count: "eagle_count",
    color: "custom-light-yellow",
  },
  SEAHORSE: {
    img: FaSeaHorse,
    houseImg: SeahorseHouse,
    title: "House of Seahorse",
    strengths: "Artistic & Realistic",
    motto: "Create beauty and bring ideas to life.",
    para: `The House of Seahorse represents creativity and innovation. Seahorses blend imagination with practical wisdom, offering fresh perspectives and solutions. Your unique ability to inspire growth and collaboration brings harmony to every situation.`,
    count: "seahorse_count",
    color: "primary-green",
  },
};

export const Ethnicities = [
  // African Ethnicities
  {
    group: "African Ethnicities",
    options: [
      { label: "Black or African American", value: "black_african_american" },
      { label: "Afro-Caribbean", value: "afro_caribbean" },
      { label: "Afro-Latino", value: "afro_latino" },
      { label: "Somali", value: "somali" },
      { label: "Nigerian", value: "nigerian" },
      { label: "Ethiopian", value: "ethiopian" },
      { label: "Ghanaian", value: "ghanaian" },
      { label: "Kenyan", value: "kenyan" },
      { label: "South African", value: "south_african" },
      { label: "Other African ", value: "other_african" },
    ],
  },
  // Asian Ethnicities
  {
    group: "Asian Ethnicities",
    options: [
      { label: "East Asian", value: "east_asian" },
      { label: "Chinese", value: "chinese" },
      { label: "Japanese", value: "japanese" },
      { label: "Korean", value: "korean" },
      { label: "Taiwanese", value: "taiwanese" },
      { label: "Southeast Asian", value: "southeast_asian" },
      { label: "Filipino", value: "filipino" },
      { label: "Vietnamese", value: "vietnamese" },
      { label: "Thai", value: "thai" },
      { label: "Malaysian", value: "malaysian" },
      { label: "Indonesian", value: "indonesian" },
      { label: "Cambodian", value: "cambodian" },
      { label: "Other Southeast Asian ", value: "other_southeast_asian" },
      { label: "South Asian", value: "south_asian" },
      { label: "Indian", value: "indian" },
      { label: "Pakistani", value: "pakistani" },
      { label: "Bangladeshi", value: "bangladeshi" },
      { label: "Sri Lankan", value: "sri_lankan" },
      { label: "Nepali", value: "nepali" },
      { label: "Other South Asian ", value: "other_south_asian" },
      { label: "Central Asian", value: "central_asian" },
      { label: "Uzbek", value: "uzbek" },
      { label: "Kazakh", value: "kazakh" },
      { label: "Other Central Asian ", value: "other_central_asian" },
    ],
  },
  // Middle Eastern and North African (MENA)
  {
    group: "Middle Eastern and North African (MENA)",
    options: [
      { label: "Arab", value: "arab" },
      { label: "Persian (Iranian)", value: "persian_iranian" },
      { label: "Turkish", value: "turkish" },
      { label: "Kurdish", value: "kurdish" },
      { label: "Egyptian", value: "egyptian" },
      { label: "Moroccan", value: "moroccan" },
      { label: "Other Middle Eastern or North African ", value: "other_mena" },
    ],
  },
  // European Ethnicities
  {
    group: "European Ethnicities",
    options: [
      { label: "White or Caucasian", value: "white_caucasian" },
      { label: "British", value: "british" },
      { label: "Irish", value: "irish" },
      { label: "German", value: "german" },
      { label: "French", value: "french" },
      { label: "Italian", value: "italian" },
      { label: "Spanish", value: "spanish" },
      { label: "Portuguese", value: "portuguese" },
      { label: "Russian", value: "russian" },
      { label: "Polish", value: "polish" },
      {
        label: "Scandinavian (Swedish, Norwegian, Danish)",
        value: "scandinavian",
      },
      { label: "Greek", value: "greek" },
      { label: "Other European ", value: "other_european" },
    ],
  },
  // Indigenous and Native Peoples
  {
    group: "Indigenous and Native Peoples",
    options: [
      { label: "Native American", value: "native_american" },
      { label: "First Nations (Canada)", value: "first_nations" },
      { label: "Māori (New Zealand)", value: "maori" },
      { label: "Aboriginal (Australia)", value: "aboriginal_australia" },
      {
        label: "Indigenous Latin American (Quechua, Aymara, etc.)",
        value: "indigenous_latin_american",
      },
      {
        label: "Other Indigenous or Native ",
        value: "other_indigenous_native",
      },
    ],
  },
  // Hispanic or Latino Ethnicities
  {
    group: "Hispanic or Latino Ethnicities",
    options: [
      { label: "Mexican", value: "mexican" },
      { label: "Puerto Rican", value: "puerto_rican" },
      { label: "Cuban", value: "cuban" },
      { label: "Dominican", value: "dominican" },
      { label: "Salvadoran", value: "salvadoran" },
      { label: "Colombian", value: "colombian" },
      { label: "Brazilian", value: "brazilian" },
      { label: "Other Hispanic or Latino ", value: "other_hispanic_latino" },
    ],
  },
  // Pacific Islander Ethnicities
  {
    group: "Pacific Islander Ethnicities",
    options: [
      { label: "Native Hawaiian", value: "native_hawaiian" },
      { label: "Samoan", value: "samoan" },
      { label: "Tongan", value: "tongan" },
      { label: "Fijian", value: "fijian" },
      { label: "Other Pacific Islander ", value: "other_pacific_islander" },
    ],
  },
  // Caribbean Ethnicities
  {
    group: "Caribbean Ethnicities",
    options: [
      { label: "Jamaican", value: "jamaican" },
      { label: "Haitian", value: "haitian" },
      { label: "Trinidadian/Tobagonian", value: "trinidadian_tobagonian" },
      { label: "Other Caribbean ", value: "other_caribbean" },
    ],
  },
  // Mixed or Multiple Ethnicities
  {
    group: "Mixed or Multiple Ethnicities",
    options: [{ label: "Mixed Ethnicities ", value: "mixed_ethnicities" }],
  },
  // Other or Prefer not to say
  {
    group: "Other",
    options: [
      { label: "Other ", value: "other" },
      { label: "Prefer not to say", value: "prefer_not_to_say" },
    ],
  },
];

export const Genders = [
  {
    value: "M",
    label: "Male",
  },
  {
    value: "F",
    label: "Female",
  },
  {
    value: "O",
    label: "Others",
  },
];

export const PerPageList = [
  {
    value: 10,
    label: 10,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
];

export const NotSure = "not_sure";

export const houseCardsData = [
  {
    id: 1,
    icon: <FaLion className="h-full w-full p-2" />,
    title: "Lion",
    subHeader: "Enterprising & Conventional",
    description:
      "Bold and fearless, Lions lead with authority and thrive in structured environments. With a sharp focus on achieving goals, you inspire others to follow your vision.",
  },
  {
    id: 2,
    icon: <FaEagle className="h-full w-full p-2" />,
    title: "Eagle",
    subHeader: "Investigative & Social",
    description:
      "Eagles soar high with wisdom and charisma. You combine sharp analytical skills with the power to connect deeply with others, making you both insightful & compassionate.",
  },
  {
    id: 3,
    icon: <FaSeaHorse className="h-full w-full p-2" />,
    title: "Seahorse",
    subHeader: "Artistic & Realistic",
    description:
      "Seahorses are a blend of creativity and practicality. Your artistic flair is grounded by a realistic perspective, allowing you to turn imaginative ideas into meaningful realities.",
  },
];

export const HOUSE_DETAILS = {
  lion: {
    id: 1,
    icon: <FaLion className="h-auto w-full p-2 animated-svg" />,
    title: "Lion",
    traits: "Enterprising & Conventional",
    motto: "Lead with courage and inspire others to greatness.",
    description:
      "Lions are natural-born leaders, thriving in structured and goal-driven environments. With an unshakable determination, you take charge, organize efficiently, and inspire those around you to achieve collective success.",
    coreStrengths:
      "Visionary leadership, persistence, and a knack for strategic planning.",
    symbolOfStrength: "The Lion represents bravery, authority, and resilience.",
    themeColor: houseColors.LION,
  },

  eagle: {
    id: 2,
    icon: <FaEagle className="h-auto w-full p-2 animated-svg" />,
    title: "Eagle",
    traits: "Investigative & Social",
    motto: "Seek wisdom and connect with purpose.",
    description:
      "Eagles are the visionaries who balance sharp intellect with an innate ability to connect with people. You are curious and love to explore, analyze, and solve complex problems while building meaningful relationships along the way.",
    coreStrengths: "Analytical thinking, empathy, and a thirst for knowledge.",
    symbolOfStrength:
      "The Eagle embodies clarity, ambition, and an expansive perspective.",
    themeColor: houseColors.EAGLE,
  },

  seahorse: {
    id: 3,
    icon: <FaSeaHorse className="h-auto w-full p-2 animated-svg" />,
    title: "Seahorse",
    traits: "Artistic & Realistic",
    motto: "Create beauty and bring ideas to life.",
    description:
      "Seahorses thrive on creative expression, balanced by a grounded and practical approach. You have a unique ability to see the world through an imaginative lens while staying realistic about bringing your visions to fruition.",
    coreStrengths: "Creativity, adaptability, and hands-on problem-solving.",
    symbolOfStrength:
      "The Seahorse represents harmony, resilience, and innovation",
    themeColor: houseColors.SEAHORSE,
  },
};
