import { useRoutes } from 'react-router-dom'; 
import PrivateRoutes from './PrivateRoutes'; 
import AuthRoutes from './AuthRoutes'; 
import Miscellaneous from './Miscellaneous';
import useJwt from '../Auth/jwt/useJwt';
import AdminRoutes from './AdminRoutes'; 
import PublicRoutes, { PrintPublicRoutes } from './PublicRoutes';

const AppRoutes = () => {
    const userData = useJwt.getUserData() 
    const routes = userData?.is_admin ? [...AdminRoutes, ...AuthRoutes, ...Miscellaneous] : [ ...PublicRoutes,...PrintPublicRoutes, ...PrivateRoutes, ...AuthRoutes, ...Miscellaneous] 
    return useRoutes(routes); 
};


export default AppRoutes;
